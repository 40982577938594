import React,{useState, useContext} from "react";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";

import {getErrorMessage,put,USERS} from "../services/Client";
import {UserContext} from "./App";

import {makeStyles} from "@mui/styles";
import {Button, IconButton, Grid, Paper} from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';

import {TextInput, TextItem} from "react-layout-components/dist";
import ChangePasswordModal from "../components/modals/ChangePasswordModal";

import edit from "../images/icons/in-schermata-edit.svg";
import saveImage from "../images/icons/in-schermata-salva.svg";
import discard from "../images/icons/in-schermata-beacon-chiudi.svg";
import userImage from "../images/icons/top_bar-profilo-over.svg";

const useStyles = makeStyles(theme => ({
    page: {
        padding: theme.spacing(2)
    },
    container: {
        marginBottom: theme.spacing(2)
    }
}));

export default function ProfilePage (){

    const {t} = useTranslation();
    let classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    let user = useContext(UserContext);

    let [editingUser, setEditingUser] = useState(null);
    let [isEditingCredentials, setIsEditingCredentials] = useState(false);

    const onChange = (newValue, field) => {
        setEditingUser({ ...editingUser, [field]: newValue });
    }

    const form = [
        {
            name: "firstName",
            label: t('name')
        },
        {
            name: "lastName",
            label: t('lastName')
        },
        {
            name: "email",
            label: t('email')
        },
        {
            name: "role",
            label: t('role'),
            notEditable: true
        },
    ]

    const saveEdit = () => {
        enqueueSnackbar(t('saving'), {variant: "info"});
        put(`${USERS}/${editingUser.id}`,{body: editingUser}).then(() => {
            user.onLogin();
            enqueueSnackbar(t('saved'), {variant: "success"});
            setEditingUser(null)
        }).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
    }

    const discardEdit = () => {
        setEditingUser(null);
    }

    if(editingUser) return <Paper className={classes.page}>

        <Grid container className={classes.container}>
            <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton onClick={saveEdit} size="large">
                    <img src={saveImage} alt="save"/>
                </IconButton>
                <IconButton onClick={discardEdit} size="large">
                    <img src={discard} alt="discard"/>
                </IconButton>
            </Grid>
            <Grid item xs={12} style={{display: "flex", "justify-content": "center"}}>
                <img src={userImage} style={{height: "6rem"}} alt="userImage"/>
            </Grid>
        </Grid>

        <Grid container spacing={3} style={{padding: "0.5rem 1rem"}}>
            {
                form.map(f => !f.notEditable && <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
                    <TextInput value={editingUser[f.name]} onTextChange={(value) => onChange(value, f.name)}
                               label={f.label} type={f.type}/>
                </Grid>)
            }
        </Grid>
    </Paper>

    return (
        <Paper className={classes.page}>
            <Grid container justifyContent="space-between" className={classes.container}>
                <Grid item>
                    {!editingUser &&
                        <Button
                            color="secondary"
                            startIcon={<LockIcon />}
                            onClick={() => setIsEditingCredentials(true)}
                        >
                            {t('changePassword')}
                        </Button>
                    }
                </Grid>
                <Grid item alignItems={"flex-end"}>
                    <IconButton onClick={() => setEditingUser(user.data)} size="large">
                        <img src={edit} alt="edit"/>
                    </IconButton>
                </Grid>
                <Grid item xs={12} style={{display: "flex", "justify-content": "center"}}>
                    <img src={userImage} style={{height: "6rem"}} alt="userImage"/>
                </Grid>
            </Grid>

            <Grid container alignItems={"flex-end"} className={classes.container}>
                {
                    form.map(f => <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
                            <TextItem label={f.label}>
                                {user.data[f.name] || ""}
                            </TextItem>
                        </Grid>)
                }
            </Grid>

            <ChangePasswordModal open={isEditingCredentials} onClose={() => setIsEditingCredentials(false)}/>
        </Paper>
    );
}
