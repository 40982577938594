import React from "react";
import {makeStyles,withStyles} from "@mui/styles";
import {Table,TableBody,TableCell,TableRow} from "@mui/material";
import BaseModal from "../react-layout-components-overwrite/BaseModal";

export default function EntityModal({entity, open, onClose, updatedFields}) {
    let fields = [];
    for (const key in entity) {
        fields.push(
            {
                field: key,
                value: entity[key]
            }
        );
    }

    //todo nascondi il tasto di salvataggio della modale
    return (
        <BaseModal open={open} onClose={onClose}>
            <TableList items={fields} updatedFields={updatedFields}/>
        </BaseModal>
    );
}


const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 70,
    },
    highLight: {
        color: theme.palette.info.dark
    }
}));

const StyledTableCell = withStyles((theme) => ({
    root: {
        borderBottom: '1px solid '+theme.palette.secondary.main,
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            background: '#0000',
        }
    },
}))(TableRow);

function TableList({items, updatedFields}) {
    const classes = useStyles();

    let rowList = items.map((item) => {
        const className = updatedFields.find(v => v.field === item.field) && classes.highLight;
        return (
            <StyledTableRow key={[item.field]}>
                {/*highlight row that are updated*/}
                <StyledTableCell className={className}>
                    <b>{item.field}</b>
                </StyledTableCell>
                <StyledTableCell className={className}>
                    {item.value}
                </StyledTableCell>
            </StyledTableRow>
        );
    });
    return (
        <div>
            <Table className={classes.table}>
                <TableBody>
                    {rowList}
                </TableBody>
            </Table>
        </div>
    );
}