import React,{useEffect,useState} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";

import {Grid} from "@mui/material";
import {TextInput} from "react-layout-components/dist";
import BaseModal from "../react-layout-components-overwrite/BaseModal";
import {useGetLayouts} from "../../services/ContentManager";
import {getErrorMessage,MAPS,post,put} from "../../services/Client";
import LayoutSelector from "./LayoutSelector";

export default function MapModal({defaultData, onClose}) {

    const {t} = useTranslation();
    let queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    let location = useSelector(store => store.session.location);
    let {layouts = []} = useGetLayouts()
    let [map, setMap] = useState({});
    let [layoutsList, setLayoutsList] = useState([]);
    let [errors, setErrors] = useState({});

    useEffect(() => {
        setMap(defaultData)
        if(layoutsList.length > 0 && defaultData?.layout === undefined) {
            setMap({layout : layoutsList[ 0 ].id,...defaultData})
        }
    }, [defaultData])

    useEffect(() => {
        if(layouts.length > 0) {
            const localLayouts = layouts.filter(l => l.attributeId === location.attribute)
            setLayoutsList(localLayouts)
            if(map.layout === undefined) {
                setMap(m => {
                    return {layout : localLayouts[ 0 ].id, ...m}
                })
            }
        }
    }, [layouts])

    function onChange(name, value) {
        let newMap = Object.assign(map, {[name]: value});
        setMap(newMap);
        setErrors({});
    }

    const submit = () => {
        if(map.name === "" || map.name === undefined) {
            setErrors({"name": t('mandatoryField')});
            return;
        }
        enqueueSnackbar(t('saving'), {variant: "info"});
        if(map.id) {
            put(`${MAPS}/${map.id}`, {body: map})
                .then(() => {
                    enqueueSnackbar(t('saved'), {variant: "success"});
                    onClose();
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries([MAPS, {lid: location.id}]));
        } else {
            post(MAPS, {body: {locationId: location.id, ...map}})
                .then(() => {
                    enqueueSnackbar(t('saved'), {variant: "success"});
                    onClose();
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                .finally(() => queryClient.invalidateQueries([MAPS, {lid: location.id}]));
        }
    }

    return <BaseModal open={defaultData !== null} onClose={onClose} onSave={submit}>
        <Grid container spacing={2} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
            <Grid item xs={12}>

                <TextInput label={t('name')} value={map.name} type="text" required
                           onTextChange={(value) => onChange("name", value)}
                           error={errors["name"]} noHelperText={true} color="fullDark"/>

            </Grid>
            <Grid item xs={12}>
                <LayoutSelector layoutsList={layoutsList} selected={map.layout}
                                onClick={(value) => onChange("layout", value)}
                />
            </Grid>
        </Grid>
    </BaseModal>
}