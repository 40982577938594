import {AddNewButton as DefaultAddNewButton} from "react-layout-components/dist";

export default function AddNewButton({onClick}){

    return <DefaultAddNewButton
        onClick={onClick}
        addIcon={{
            src: require("../../images/icons/in-schermata-aggiungi.svg").default,
            hoverSrc: require("../../images/icons/in-schermata-aggiungi-over.svg").default
        }}
    />
}