import {useContext,useEffect,useState} from "react";
import {useParams} from "react-router-dom";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useTranslation} from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import {Paper} from "@mui/material";
import {FilterAndSearchBar} from "react-layout-components/dist";

import {BEACONS,deleteElem,getErrorMessage,put} from "../services/Client";
import {useGetBeacons,useGetMaps} from "../services/ContentManager";
import BeaconPopup from "../components/popup/BeaconPopup";
import BeaconModal from "../components/modals/BeaconModal";
import LayerController from "../components/mapview/LayerController";
import MapView from "../components/mapview/MapView";
import {UserContext} from "./App";
import {canEditResources} from "../services/PermissionManager";

import legendBeacon from "../images/icons/legend-beacon.svg";
import legendBeaconHover from "../images/icons/legend-beacon-over.svg";
import legendBeaconOff from "../images/icons/legend-beacon-off.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: "0.3rem"
    },
    page: {
        padding: theme.spacing(2)
    }
}));

export default function Maps() {
    let {map, id} = useParams();
    let {maps = []} = useGetMaps();
    let [selectedMap, setSelectedMap] = useState();

    let {beacons = []} = useGetBeacons();
    let [beaconsLayer, setBeaconsLayer] = useState(true);
    let [addBeacon, setAddBeacon] = useState(false);
    let [beaconModal, setBeaconModal] = useState(false);
    let [newBeacon, setNewBeacon] = useState({});
    let [dragBeacon, setDragBeacon] = useState();

    let [popup, setPopup] = useState({});
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    const {t} = useTranslation();
    let user = useContext(UserContext).data;

    if (maps) maps = maps.filter(m => m.floor !== -1);
    if (maps.length > 0 && !selectedMap) setSelectedMap(parseInt(map) || maps[0].id);

    useEffect(() => {
        id && beacons[0] && onBeaconClick({id: parseInt(id)}, "left");
    }, []);

    function onBeaconClick(e, popupPosition) {
        let beacon = beacons.find((beacon) => beacon.id === e.id);
        if (popup.id && popup.id === e.id) {
            setPopup({});
            return;
        }
        let newPopup = <BeaconPopup
            beacon={beacon}
            popupPosition={popupPosition}
            onEdit={canEditResources(user) && (() => {
                setPopup({});
                setNewBeacon(beacon);
                setBeaconModal(true);
            })}
            onMove={canEditResources(user) && (() => {
                setPopup({});
                setDragBeacon(beacon.id);
            })}
            onDelete={canEditResources(user) && (() => {
                enqueueSnackbar(t('deleting'), {variant: "info"});
                deleteElem(BEACONS, {elem: beacon.id})
                    .then(() => {
                        enqueueSnackbar(t('deleted'),{variant : "success"});
                        setPopup({});
                    })
                    .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
                    .finally(() => queryClient.invalidateQueries("beacons"));
            })}
        />;
        setPopup({container: newPopup, id: e.id});
    }

    function onDragBeacon(position, beacon) {
        beacon.x = position.x;
        beacon.y = position.y;
        put(BEACONS, {body: beacon, elem: beacon.id})
            .then(() => enqueueSnackbar(t('saved'), {variant: "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries("beacons"));
    }

    let customCursor;
    if (addBeacon) customCursor = require("../images/icons/on-map-beacon-add.svg").default;

    return (
        <div className={classes.root}>
            <div style={{marginBottom: "0.3rem"}}>
                {maps && maps.length > 1 &&
                    <FilterAndSearchBar
                        selected={selectedMap}
                        filters={maps}
                        onFilter={(id) => {
                            setSelectedMap(id);
                            setPopup({});
                        }}
                    />
                }
            </div>
            <Paper className={classes.page}>
                <MapView map={selectedMap}
                         mapWidth={selectedMap && maps.find((m) => m.id === selectedMap).width}
                         mapHeight={selectedMap && maps.find((m) => m.id === selectedMap).height}
                         beacons={beaconsLayer && beacons.filter(b => b.map === selectedMap)}
                         customCursor={customCursor}
                         onBackgroundClick={(newElement) => {
                             setPopup({});
                             if (addBeacon) {
                                 setNewBeacon(newElement);
                                 setBeaconModal(true);
                             }
                         }}
                         dragBeacon={dragBeacon}
                         onDragBeacon={onDragBeacon}
                         onBeaconClick={onBeaconClick}
                         popup={popup.container}>
                    <LayerController
                        title={t('beacons').toUpperCase()}
                        src={legendBeacon}
                        hover={legendBeaconHover}
                        off={legendBeaconOff}
                        color={"#FF0D00"}
                        onClick={() => setBeaconsLayer(!beaconsLayer)}
                        addElement={() => setAddBeacon(!addBeacon)}
                        actionText={addBeacon ? "-" : "+"}
                        enabled={beaconsLayer}
                        canAddElement={canEditResources(user)}
                    />
                </MapView>
            </Paper>
            <BeaconModal beacon={newBeacon} open={beaconModal} onClose={() => {
                setBeaconModal(false);
                setAddBeacon(false);
            }}/>
        </div>
    );
}