import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {useGetAttributes,useGetLayouts} from "../../services/ContentManager";
import BaseModal from "../react-layout-components-overwrite/BaseModal";
import {Grid,ListItemIcon,ListItemText,MenuItem} from "@mui/material";
import React,{useEffect,useState} from "react";
import {TextInput} from "react-layout-components";
import LayoutSelector from "./LayoutSelector";
import {getErrorMessage,LOCATIONS,MAPS,put} from "../../services/Client";


export default function EditLocationAttributeModal({open, onClose}){

    const {t} = useTranslation();
    let queryClient = useQueryClient();
    const {enqueueSnackbar} = useSnackbar();
    let location = useSelector(store => store.session.location);
    let {attributes = []} = useGetAttributes();
    let {layouts = []} = useGetLayouts()
    let [newAttributeId, setNewAttributeId] = useState(null);
    let [newLayoutId, setNewLayoutId] = useState(null);

    useEffect(() => {
        if(newAttributeId === null || newLayoutId === null) {
            let newAttId = attributes.filter(a => a.id !== location.attribute)[ 0 ].id
            setNewAttributeId(newAttId)
            let firstLayoutWithAID = layouts.filter(l => l.attributeId === newAttId)[ 0 ]
            if(firstLayoutWithAID) setNewLayoutId(firstLayoutWithAID.id)
        }
    }, [location.attribute, attributes, layouts])

    const onChangeNewAttributeId = (newValue) => {
        setNewAttributeId(newValue)
        let firstLayoutWithAID = layouts.filter(l => l.attributeId === newValue)[0]
        if(firstLayoutWithAID) setNewLayoutId(firstLayoutWithAID.id)
    }

    const submit = () => {
        enqueueSnackbar(t('saving'), {variant: "info"});
        put(`${LOCATIONS}/${location.id}/updateAttribute`, {body: {newAttributeId, newLayoutId}})
            .then(() => {
                enqueueSnackbar(t('saved'), {variant: "success"});
                onClose();
            })
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([LOCATIONS]));
    }

    return  <BaseModal open={open} onClose={onClose} onSave={submit}>
        <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>
            <Grid item xs={12} style={{display: "flex"}}>
                <TextInput label={t('newLocationType')} value={newAttributeId || ''} type="select" select
                           onTextChange={value => onChangeNewAttributeId(value)} color="fullDark">
                    {attributes.filter(a => a.id !== location.attribute).map(a =>
                        <MenuItem key={a.id} value={a.id}>
                            <Grid container direction="row">
                                <ListItemIcon>
                                    <img src={a.icon}/>
                                </ListItemIcon>
                                <ListItemText primary={a.name}/>
                            </Grid>
                        </MenuItem>)}
                </TextInput>
            </Grid>
            <Grid item xs={12}>
                <LayoutSelector layoutsList={layouts.filter(l => l.attributeId === newAttributeId)}
                                title={t('newLayoutForMap')}
                                selected={newLayoutId}
                                onClick={(value) => setNewLayoutId(value)}
                />
            </Grid>
        </Grid>
    </BaseModal>
}