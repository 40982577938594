import {AUTH,get,getErrorMessage,post} from "./Client";
import {TokenManager} from "./TokenManager";
let tokenManager = new TokenManager();

export function login(email, password) {
    return new Promise((resolve, reject) => {
        post(`${AUTH}/login`, {body: {email, password}})
            .then((data) => {
                tokenManager.save(data.token)
                resolve({developer: data.user})
            })
            .catch(e => {
                let message = getErrorMessage(e)
                reject(message)
            });
    })
}

export function refreshToken() {
    return new Promise((resolve, reject) => {
        let token = tokenManager.getAllTokens()
        if(token && token.refreshToken) {
            return get(`${AUTH}/refresh`, {params: {t: token.refreshToken}}).then((data) => {
                token.accessToken = data.token.accessToken
                tokenManager.save(token)
                resolve({developer: data.user})
            }).catch(e => reject(getErrorMessage(e)))
        } else {
            reject()
        }
    })
}

export function changePassword(email, oldPass, newPass) {
    return new Promise((resolve, reject) => {
        post(`${AUTH}/password/challenge`, {body: { email, oldPass, newPass}})
            .then((data) => {
                tokenManager.save(data.token)
                resolve({developer: data.user})
            })
            .catch(e => reject(getErrorMessage(e)))
    })
}

export function forgottenPasswordRequest(email) {
    return new Promise((resolve, reject) => {
        post(`${AUTH}/password/forgotten/request`,{params : {email}})
            .then((data) => resolve(data))
            .catch(e => reject(getErrorMessage(e)))
    })
}

export function forgottenPasswordRestore(confirmationCode, email, newPassword) {
    return new Promise((resolve, reject) => {
        post(`${AUTH}/password/forgotten/restore`,{body: {confirmationCode, email, newPassword}})
            .then((data) => resolve(data))
            .catch(e => reject(getErrorMessage(e)))
    })
}