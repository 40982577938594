import React, {useEffect, useRef, useState} from "react";

import Grid from "@mui/material/Grid";
import makeStyles from "@mui/styles/makeStyles";
import {alpha,useMediaQuery,useTheme} from "@mui/material";
import {HoverableButton, HoverableIconButton} from "react-layout-components/dist";

import {download,MAPS} from "../../services/Client";
import IndoorMap from "./IndoorMap";

import collapseMenu from "../../images/icons/collapse-menu.svg"
import collapseMenuHover from "../../images/icons/collapse-menu-over.svg"

const useStyles = makeStyles((theme) => ({
	actionsContainer: {
		position: "relative",
		width: "fit-content",
		height: "3rem",
		blockSize: "fit-content",
		color: "white",
		padding: "1rem",
		zIndex: 9,
		marginBottom: "-3rem"
	},
	actions: {
		backgroundColor: alpha(theme.palette.primary.contrastText, 0.6),
		padding: "0.4rem"
	},
	collapseBody: {
		backgroundColor: theme.palette.secondary.main,
		height : "50%",
		width: "100%"
	},
	reversedImage: {
		"-webkit-transform": "scaleX(-1)",
		transform: "scaleX(-1)"
	}
}));

export default function MapView({map, beacons, onBeaconClick, dragBeacon, onDragBeacon,
									customCursor, onBackgroundClick, popup, children}) {
	const classes = useStyles()

	const stage = useRef();
	const mapContainer = useRef();
	const theme = useTheme();
	const windowLessThanMedium = useMediaQuery(theme.breakpoints.down("md"));

	let [scale, setScale] = useState(1);
	let [containerWidth, setContainerWidth] = useState();
	let [isChildrenCollapsed, setIsChildrenCollapsed] = useState(false);
	let [mapImage, setMapImage] = useState();

	const handleWindowResize = () => {
		if(mapContainer.current) setContainerWidth(mapContainer.current.getBoundingClientRect().width)
	}
	React.useEffect(() => {
		window.addEventListener("resize", handleWindowResize, false);
	}, []);

	useEffect(() => {
		if(mapContainer.current) setContainerWidth(mapContainer.current.getBoundingClientRect().width)
	}, [isChildrenCollapsed, mapContainer.current]);

	function addZoom() {
		let oldScale = stage.current.scaleX();
		let newScale = oldScale * 1.3;
		stage.current.scale({x: newScale, y: newScale});
		stage.current.batchDraw();
		setScale(newScale);
	}

	function subZoom() {
		let oldScale = stage.current.scaleX();
		let newScale = oldScale / 1.3;
		if (newScale < 0.5) newScale = 0.5;
		stage.current.scale({x: newScale, y: newScale});
		stage.current.batchDraw();
		setScale(newScale);
	}

	function resetZoom() {
		stage.current.scale({x: 1, y: 1});
		stage.current.position({x: 0, y: 0});
		stage.current.batchDraw();
		setScale(1);
	}

	function onWheel({evt}) {
		evt.preventDefault();

		let oldScale = stage.current.scaleX();

		let newScale = evt.deltaY > 0 ? oldScale * 1.1 : oldScale / 1.1;
		if (newScale < 0.5) newScale = 0.5;
		stage.current.scale({x: newScale, y: newScale});

		let mousePointTo = {
			x: stage.current.getPointerPosition().x / oldScale - stage.current.x() / oldScale,
			y: stage.current.getPointerPosition().y / oldScale - stage.current.y() / oldScale
		};
		let newPos = {
			x: -(mousePointTo.x - stage.current.getPointerPosition().x / newScale) * newScale,
			y: -(mousePointTo.y - stage.current.getPointerPosition().y / newScale) * newScale
		};
		stage.current.position(newPos);
		stage.current.batchDraw();
		setScale(newScale);
	}

	useEffect(() => {
		setMapImage("downloading");
		map && download(`${MAPS}/${map}/image`)
			.then(response => setMapImage(URL.createObjectURL(response.data)))
			.catch(e => console.log(e));
	}, [map]);

	let containerHeight = map && map.width && containerWidth ? containerWidth / map.width * map.height : 600;
	return <Grid container spacing={2} style={{cursor: "url("+ customCursor +"), auto"}}>
		<Grid item xs={12} md={isChildrenCollapsed ? 11.8 : 7.8}>
			<Grid ref={mapContainer}>
				{mapImage && mapImage !== "downloading" &&
					<IndoorMap
						map={map}
						refElem={stage}
						scale={scale}
						mapImage={mapImage}
						containerWidth={containerWidth}
						containerHeight={containerHeight}
						onWheel={onWheel}
						beacons={beacons}
						dragBeacon={dragBeacon}
						onDragBeacon={onDragBeacon}
						onBeaconClick={e => onBeaconClick(e.currentTarget.attrs,stage.current.getPointerPosition().x > containerWidth / 2? "left" : "right")}
						onBackgroundClick={onBackgroundClick}
					/>
				}
			</Grid>
			{
				mapImage && mapImage !== "downloading" && containerWidth && containerHeight &&
				<div className={classes.actionsContainer}
					 style={containerWidth && containerHeight && {left : containerWidth-166, top: -(containerHeight)+10}}>
					<Grid container direction={"row"} spacing={2} className={classes.actions} wrap="nowrap">
						<HoverableIconButton
							onClick={addZoom}
							src={require("../../images/icons/map-zoom-in.svg").default}
							hoverSrc={require("../../images/icons/map-zoom-in-over.svg").default}
						/>
						<HoverableIconButton
							onClick={subZoom}
							src={require("../../images/icons/map-zoom-out.svg").default}
							hoverSrc={require("../../images/icons/map-zoom-out-over.svg").default}
						/>
						<HoverableIconButton
							onClick={resetZoom}
							src={require("../../images/icons/map-reset.svg").default}
							hoverSrc={require("../../images/icons/map-reset-over.svg").default}
						/>
					</Grid>
				</div>
			}
			{popup}
		</Grid>
		{!windowLessThanMedium &&
			<Grid container item md={0.1} justifyContent="center" alignItems="center">
				<HoverableButton
					onClick={() => setIsChildrenCollapsed(!isChildrenCollapsed)}
					hoverChildren={<img src={collapseMenuHover} className={isChildrenCollapsed && classes.reversedImage} alt={"collapse"}/>}
					children={<img src={collapseMenu} className={isChildrenCollapsed && classes.reversedImage} alt={"collapse"}/>}
				/>
			</Grid>
		}
		{!isChildrenCollapsed &&
			<Grid item xs={12} md={3.8}>
				{
					mapImage && mapImage !== "downloading" && children
				}
			</Grid>
		}
	</Grid>
}
