const attributes = [
	{
		id: 1,
		name: "Station",
		icon: require("../images/icons/venue-train-station-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-station-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-station-50.svg").default,
		location: true
	},
	{
		id: 2,
		name: "Urban Stop",
		icon: require("../images/icons/venue-urban-bus-stop-top-bar-24.svg").default,
		hover: require("../images/icons/venue-urban-bus-stop-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-urban-bus-stop-50.svg").default,
		location: true
	},
	{
		id: 3,
		name: "Extra-urban Stop",
		icon: require("../images/icons/venue-extra-urban-bus-stop-top-bar-24.svg").default,
		hover: require("../images/icons/venue-extra-urban-bus-stop-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-extra-urban-bus-stop-50.svg").default,
		location: true
	},
	{
		id: 4,
		name: "Mixed Bus Stop",
		icon: require("../images/icons/venue-extra-urban-bus-stop-top-bar-24.svg").default,
		hover: require("../images/icons/venue-extra-urban-bus-stop-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-extra-urban-bus-stop-50.svg").default,
		location: true
	},
	{
		id: 11,
		name: "Train",
		icon: require("../images/icons/venue-train-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-50.svg").default,
		location: false
	},
	{
		id: 12,
		name: "Urban bus",
		icon: require("../images/icons/venue-urban-bus-top-bar-24.svg").default,
		hover: require("../images/icons/venue-urban-bus-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-urban-bus-50.svg").default,
		location: false
	},
	{
		id: 13,
		name: "Extra urban bus",
		icon: require("../images/icons/venue-extra-urban-bus-top-bar-24.svg").default,
		hover: require("../images/icons/venue-extra-urban-bus-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-urban-bus-50.svg").default,
		location: false
	},
	{
		id: 5,
		name: "Funicular or Rack Railway station",
		icon: require("../images/icons/venue-train-station-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-station-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-station-50.svg").default,
		location: true
	},
	{
		id: 14,
		name: "Funicular or Rack Railway train",
		icon: require("../images/icons/venue-train-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-50.svg").default,
		location: false
	},
	{
		id: 6,
		name: "Metro station",
		icon: require("../images/icons/venue-train-station-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-station-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-station-50.svg").default,
		location: true
	},
	{
		id: 15,
		name: "Metro train",
		icon: require("../images/icons/venue-train-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-50.svg").default,
		location: false
	},
	{
		id: 7,
		name: "Elevator Stop",
		icon: require("../images/icons/venue-train-station-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-station-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-station-50.svg").default,
		location: true
	},
	{
		id: 16,
		name: "Elevator",
		icon: require("../images/icons/venue-train-top-bar-24.svg").default,
		hover: require("../images/icons/venue-train-top-bar-over-24.svg").default,
		negative: require("../images/icons/venue-train-50.svg").default,
		location: false
	}
];

export default attributes;
