import {
	SET_LOCATION
} from "./actions";

const defaultState = {
	location: null
};

export default function (state = defaultState, action) {
	switch (action.type) {
		case SET_LOCATION: {
			if (action.data)
				return {
					...state,
					location: action.data
				};
			else return defaultState;
		}
		default:
			return state;
	}
}
