import React,{useContext,useState} from "react";
import Grid from "@mui/material/Grid";
import {useSnackbar} from "notistack";
import {login} from "../../services/UserManager";
import {getErrorMessage,PASSWORD,put,USERS} from "../../services/Client";
import {useTranslation} from "react-i18next";
import {UserContext} from "../../containers/App.js";
import {TextInput} from "react-layout-components/dist";
import BaseModal from "../react-layout-components-overwrite/BaseModal";

export default function ChangePasswordModal({open, onClose}) {

    const {t} = useTranslation();
    let user = useContext(UserContext).data;
    const {enqueueSnackbar} = useSnackbar();

    let [currentPassword, setCurrentPassword] = useState("");
    let [newPassword, setNewPassword] = useState("");
    let [repeatPassword, setRepeatPassword] = useState("");

    let [error, setError] = useState({});

    const save = () => {
        if (currentPassword === "") {
            setError({old: t('mandatoryField')});
            return;
        }
        if (newPassword === "") {
            setError({new: t('mandatoryField')});
            return;
        }
        if (repeatPassword === "") {
            setError({repeat: t('mandatoryField')});
            return;
        }
        if (newPassword !== repeatPassword) {
            setError({repeat: t('passwordsNotMatching'), new: t('passwordsNotMatching')});
            return;
        }
        login(user.email, currentPassword).then(() => {
            enqueueSnackbar(t('saving'), {variant: "info"});
            put(`${USERS}/${PASSWORD}`, {body: {email: user.email, newPass: newPassword, oldPass: currentPassword}})
                .then(() => {
                    enqueueSnackbar(t('saved'),{variant : "success"})
                    close()
                })
                .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
        }).catch(() => {
            setError({old: t('wrongPassword')});
        })
    }

    const close = () => {
        setCurrentPassword("");
        setNewPassword("");
        setRepeatPassword("");
        setError({});
        onClose();
    }

    return (
        <BaseModal open={open} onClose={close} onSave={save}>
            <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>

                <Grid item xs={12}>
                    <TextInput required label={t('currentPassword')} value={currentPassword}
                               type="password" showPasswordIcon color="fullDark"
                               onTextChange={(value) => {
                                   setCurrentPassword(value.trim());
                                   setError({});
                               }} error={error.old}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput required label={t('newPassword')} value={newPassword}
                               type="password" showPasswordIcon color="fullDark"
                               onTextChange={(value) => {
                                   setNewPassword(value.trim());
                                   setError({});
                               }} error={error.new}/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput required label={t('repeatPassword')} value={repeatPassword}
                               type="password" showPasswordIcon color="fullDark"
                               onTextChange={(value) => {
                                   setRepeatPassword(value.trim());
                                   setError({});
                               }} error={error.repeat}/>
                </Grid>

            </Grid>
        </BaseModal>
    )

}