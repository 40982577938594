import React,{useContext,useState} from "react";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {useSnackbar} from 'notistack';
import {useQueryClient} from 'react-query';

import {setLocation} from "../redux/session/actions";
import {BEACONS,getErrorMessage,LOCATIONS,post} from "../services/Client";
import {useGetAttributes,useGetLocations} from "../services/ContentManager";

import {FormControlLabel,Grid,Switch} from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {FilterAndSearchBar, NTMXGrid} from "react-layout-components/dist";
import AddNewButton from "../components/react-layout-components-overwrite/AddNewButton";
import LocationModal from "../components/modals/LocationModal";
import {UserContext} from "./App";
import {canEditResources} from "../services/PermissionManager";

export default function LocationList() {

	let {attributes = []} = useGetAttributes()
	let [modal, setModal] = useState(false);
	let [filter, setFilter] = useState(null);
	const {enqueueSnackbar} = useSnackbar();
	const dispatch = useDispatch();
	let queryClient = useQueryClient();
	const {t} = useTranslation();
	let user = useContext(UserContext).data;

	const {locations, status} = useGetLocations();
	if (status?.status === "error") showError(status.error);

	function showError(e) {
		enqueueSnackbar(getErrorMessage(e), {variant: "error"});
	}

	function saveVenue(location) {
		post(LOCATIONS, {body: location})
			.then(r => queryClient.invalidateQueries("venues"))
			.catch(showError)
			.finally(() => setModal(null));
	}

	if (locations && locations.length === 1) {
		dispatch(setLocation(locations[0]));
	}

	let data = locations ? locations.slice() : [];
	if (filter) data = data.filter(l => l.attribute === filter);
	data.sort((a, b) => b.id - a.id);

	let columns = [
		{
			headerName: " ", field: "attribute",
			type: 'actions', width: 40,
			renderCell: params => attributes.some(a => a.id === params.value) ?
				<img style={{height: '70%'}} src={attributes.find(a => a.id === params.value).negative}/>
				: params.value
		},
		{headerName: t('id'), field: 'id', flex: 2},
		{headerName: t('name'), field: 'name', flex: 3},
		{headerName: t('description'), field: 'description', flex: 3},
		{headerName: t('nBeacon'), field: 'n', flex: 3},
		{
			headerName: t('alert'),
			field: 'alert',
			width: 80,
			renderCell: ({value}) => value && value > 0 ?
				<ErrorOutlineIcon color={"error"}/> : <div/>
		},
	]

	const enableLocation = (lid) => {
		enqueueSnackbar(t('enabling'), {variant: "info"});
		post(`${BEACONS}/enable`,{params : {locationId: lid}})
			.then(() => enqueueSnackbar(t('enabled'), {variant: "success"}))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries(LOCATIONS));
	}

	const disableLocation = (lid) => {
		enqueueSnackbar(t('disabling'), {variant: "info"});
		post(`${BEACONS}/disable`,{params : {locationId: lid}})
			.then(() => enqueueSnackbar(t('disabled'), {variant: "success"}))
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
			.finally(() => queryClient.invalidateQueries(LOCATIONS));
	}

	if(process.env.REACT_APP_ENVIRONMENT === "demo-monaco") {
		columns.splice(columns.length - 1,0,{
			headerName : t('enabled'),
			field : 'enabled',
			type: 'actions',
			width : 120,
			renderCell: (params) => {
				let notChecked = params.row.n > 0 && params.row.enabledBeacons === 0
				return <FormControlLabel
					key="enabling"
					control={
						<Switch
							checked={!notChecked}
							disabled={params.row.n === 0}
							onChange={event => {
								event.stopPropagation()
								if(notChecked) enableLocation(params.row.id)
								else disableLocation(params.row.id)
							}}
						/>
					}
				/>
			}
		})
	}

	return (
		< >
			<Grid container alignItems={"center"}>
				<FilterAndSearchBar
					allButton
					filters={attributes}
					selected={filter}
					onFilter={setFilter}
				/>
			</Grid>
			<Grid item xs={12} style={{paddingTop: "0.5rem"}}>
				<NTMXGrid
					rows={data}
					columns={columns}
					onRowClick={(params, event) => {
						const isActionCell = !!event.target.closest('.MuiDataGrid-actionsCell');
						if (!isActionCell) {
							dispatch(setLocation(params.row))
						}
					}}
					rightButton={canEditResources(user) && <Grid container justifyContent={"flex-end"}>
						<AddNewButton onClick={() => setModal(true)}/>
					</Grid>}
					loading={data.length === 0 && status?.status === "loading"}
				/>
			</Grid>
			<LocationModal
				open={modal}
				onClose={() => setModal(false)}
				onSubmit={saveVenue}
			/>
		</>
	);

}
