import React from "react";
import {useTranslation} from "react-i18next";

import {Grid} from "@mui/material";
import {ConfirmIconButton, HoverableIconButton} from "react-layout-components/dist";

import beaconIcon from "../../images/icons/on-map-beacon.svg";
import move from "../../images/icons/pop-up-beacon-sposta.svg";
import moveHover from "../../images/icons/pop-up-beacon-sposta-over.svg";
import edit from "../../images/icons/pop-up-beacon-edit.svg";
import editHover from "../../images/icons/pop-up-beacon-edit-over.svg";
import deleteIcon from "../../images/icons/pop-up-beacon-delete.svg";
import deleteIconHover from "../../images/icons/pop-up-beacon-delete-over.svg";

import BasePopup from "./BasePopup";
import TextBoxForPopup from "./TextBoxForPopup";
import BatteryForPopup from "./BatteryForPopup";

export default function BeaconPopup ({beacon, popupPosition, onMove, onEdit, onDelete}) {

    const {t} = useTranslation();

    return(
        <BasePopup popupPosition={popupPosition} header={
            <>
                <Grid item>
                    <HoverableIconButton src={beaconIcon} hoverSrc={beaconIcon}/>
                </Grid>
                <Grid item>
                    {onMove && <HoverableIconButton src={move} hoverSrc={moveHover} onClick={onMove}/>}
                    {onEdit && <HoverableIconButton src={edit} hoverSrc={editHover} onClick={onEdit}/>}
                    {onDelete && <ConfirmIconButton item={{icon : deleteIcon,iconHover : deleteIconHover}}
                                        text={t('deleteWarningWithField',{field : t('beacon').toLowerCase()})}
                                        title={`${t('confirm')}?`} onConfirm={onDelete}/>}
                </Grid>
            </>
        }>
            <TextBoxForPopup element={beacon.uuid} label={t('uuid')}/>
            <TextBoxForPopup element={beacon.major} label={t('major')}/>
            <TextBoxForPopup element={beacon.minor} label={t('minor')}/>
            <BatteryForPopup batteryLevel={beacon.battery / 7 * 100}/>
        </BasePopup>
    );
}