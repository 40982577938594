import {BaseModal as DefaultBaseModal} from "react-layout-components/dist";

export default function BaseModal({open, onClose, onSave, iconButton, submitButton, children, error, maxWidth}) {

    return <DefaultBaseModal
        open={open}
        onClose={onClose}
        onSave={onSave}
        iconButton={iconButton}
        submitButton={submitButton}
        error={error}
        maxWidth={maxWidth}
        closeButtonIcon={{
                src: require("../../images/icons/in-schermata-beacon-chiudi.svg").default,
                hoverSrc: require("../../images/icons/in-schermata-chiudi-over.svg").default
            }}
        saveButtonIcon={{
                src: require("../../images/icons/in-schermata-salva.svg").default,
                hoverSrc: require("../../images/icons/in-schermata-salva-over.svg").default
            }}
    >
        {children}
    </DefaultBaseModal>
}