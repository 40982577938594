import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useQueryClient} from "react-query";
import {useSnackbar} from "notistack";

import {TextInput} from "react-layout-components/dist";
import {Grid,MenuItem} from "@mui/material";
import BaseModal from "../react-layout-components-overwrite/BaseModal";

import {getErrorMessage,post,USERS} from "../../services/Client";
import {roles} from "../../services/PermissionManager";

export default function UserModal({open, onClose, defaultValue}) {

	const {t} = useTranslation();
	let queryClient = useQueryClient();
	const {enqueueSnackbar} = useSnackbar();
	let [user, setUser] = useState(defaultValue || {});
	let [errors, setErrors] = useState({});

	useEffect(() => setUser(defaultValue || {}), [defaultValue]);

	let getTextInput = (label, field, required) => <Grid item xs={6} style={{display: "flex"}}>
		<TextInput label={label} value={user[field] || ''} color="fullDark" error={errors[field]}
				   onTextChange={value => onChange(field, value)} required={required}/>
	</Grid>;

	function onChange(name, value) {
		setUser(user => {
			user[name] = value;
			return user;
		});
		setErrors({});
	}

	function submit() {
		if (!user.email || user.email === "") {
			setErrors({email: t('mandatoryField')});
			return;
		}
		enqueueSnackbar(t('saving'), {variant: "info"});
		post(USERS, {body: user})
			.then(() => {
				queryClient.invalidateQueries([USERS]);
				enqueueSnackbar(t('saved'), {variant: "success"});
				onClose()
			})
			.catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
	}

	return (
		<BaseModal open={open} onClose={onClose} onSave={submit}>
			<Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>

				{getTextInput(t('email'), "email", true)}

				<Grid item xs={6} style={{display: "flex"}}>
					<TextInput label={t('role')} value={user.role || ''} type={"select"} select
							   onTextChange={value => onChange("role", value)} color="fullDark">
						<MenuItem key={roles.USER} value={roles.USER}>{t('maintainer')}</MenuItem>
						<MenuItem key={roles.ADMIN} value={roles.ADMIN}>{t('admin')}</MenuItem>
					</TextInput>
				</Grid>

				{getTextInput(t('firstName'), "firstName")}
				{getTextInput(t('lastName'), "lastName")}

			</Grid>
		</BaseModal>
	);
}
