import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useSnackbar} from "notistack";
import {Link} from "@mui/material";

import {getErrorMessage} from "../../services/Client";
import {useGetBeacons,useGetMaps} from "../../services/ContentManager";
import {NTMXGrid} from "react-layout-components/dist";
import Battery from "../../components/popup/Battery";

export default function Beacons() {

    const {t} = useTranslation();
    let {beacons, error} = useGetBeacons();
    let {maps = []} = useGetMaps();
    const {enqueueSnackbar} = useSnackbar();
    let history = useHistory();

    if (error) enqueueSnackbar(getErrorMessage(error), {variant: "error"});

    function handleClick(beacon) {
        history.push("maps/" + beacon.map + "/beacon/" + beacon.id);
    }

    let lookup = {};
    maps.map(m => {
        lookup [m.id] = m.name;
    });

    return (
        <div>
            <NTMXGrid
                columns={[
                    {headerName: t('uuid'), field: 'uuid', flex: 2},
                    {headerName: t('major'), field: 'major', flex: 1},
                    {headerName: t('minor'), field: 'minor', flex: 1},
                    {
                        headerName: t('sublocation'), field: 'map', flex: 1,
                        renderCell: params => <Link onClick={() => handleClick(params.row)} underline="hover" color="secondary">
                            {lookup[params.value]}
                        </Link>,
                    },
                    {
                        headerName: t('batteryLevel'), field: 'battery', flex: 1,
                        renderCell: ({value}) => <div> {(value !== null && value !== undefined) ? value / 7 * 100 : "-"}%
                            <Battery batteryLevel={value / 7 * 100}/>
                        </div>
                    }
                ]}
                rows={beacons || []}
                title={t('beacons')}
            />
        </div>
    );
}