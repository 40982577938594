import Main from "./Main";
import React,{useState} from "react";
import {useGetMenu} from "../services/MenuManager";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import {setLocation} from "../redux/session/actions";
import {TokenManager} from "../services/TokenManager";
import {
    changePassword,
    forgottenPasswordRequest,
    forgottenPasswordRestore,
    login,
    refreshToken
} from "../services/UserManager";
import {NTMLayout} from "react-layout-components/dist";
import {languages} from "../constants/languages";
import {FormControl} from "@mui/material";
import LanguageSelect from "../components/LanguageSelect";
import i18next from "i18next";

import logo from "../images/top_bar-logo-hitachi.svg"

export const UserContext = React.createContext(null);

let tokenManager = new TokenManager();

export default function App() {

    let [user, setUser] = useState({status:{ready:false}, data:{}})

    return <UserContext.Provider value={user}>
        <AppLayout user={user} setUser={setUser}/>
    </UserContext.Provider>
}

function AppLayout({user, setUser}){

    let [selectedLanguage, setSelectedLanguage] = useState(i18next.resolvedLanguage)

    const theme = {
        palette: {
            primary: {
                // Purple and green play nicely together.
                main: '#2D3135',
                light: "#CCCCCC",
                dark: "#2D3135",
                contrastText: '#fff',
            },
            secondary: {
                main: '#A30013',
            },
            background: {
                main:'#CCCCCC',
                dark:'#2D3135',
            },
            text: {
                primary: "#12313B"
            }
        },
        typography: {
            body1: {
                fontSize: "0.875rem",
                lineHeight: 1.43,
                letterSpacing: "0.01071em",
            }
        },
        overrides: {
            MuiButton: {
                root: {
                    whiteSpace: "nowrap",
                    verticalAlign: "middle",
                    userSelect: "none",
                    border: "1px solid transparent",
                    padding: "0.75rem 1rem",
                    fontWeight: "bold",
                    textAlign: "center",
                    borderRadius: 0,
                    minWidth: "auto",
                    fontSize: "0.875rem",
                    lineHeight: "1.25",
                }
            },
            MuiTableRow: {
                root: {
                    "&:hover": {backgroundColor: '#D4EDFC',}
                }
            },
            MuiTableSortLabel: {
                root: {
                    color: '#fff',
                    '&:hover': {
                        color: '#ccc',
                    }
                },
                active: {
                    color: '#ccc !important',
                },
                icon: {
                    color: '#ccc !important',
                    '&:hover': {
                        color: '#ccc',
                    },
                },
            }
        },
    }


    let menu = useGetMenu();

    return (
        <NTMLayout
            theme={theme} menu={menu}
            user={user} setUser={setUser}
            elemSelector={<>
                <Grid sx={{paddingRight: "1rem"}}>
                    <FormControl size="small">
                        <LanguageSelect
                            value={selectedLanguage || i18next.resolvedLanguage} languages={languages}
                            onChange={({target}) => {
                                i18next.changeLanguage(target.value)
                                setSelectedLanguage(target.value)
                            }}/>
                    </FormControl>
                </Grid>
                <LocationSelector/>
            </>}
            selectedi18Language={selectedLanguage}
            logo={logo}
            login={login} refreshToken={refreshToken}
            changePassword={changePassword} forgottenPasswordRequest={forgottenPasswordRequest} forgottenPasswordRestore={forgottenPasswordRestore}
            tokenManager={tokenManager}
        >
            <Main/>
        </NTMLayout>
    )
}

const useStyles = makeStyles((theme) => ({
    locationsButton: {
        color: theme.palette.primary.contrastText,
        borderColor: theme.palette.primary.contrastText,
        '&:hover': {
            borderColor: theme.palette.primary.dark,
            color: theme.palette.primary.dark
        }
    }
}));

function LocationSelector () {

    const classes = useStyles();
    const {t} = useTranslation();
    let history = useHistory();
    let location = useSelector(store => store.session.location);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    let dispatch = useDispatch();

    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectLocation = (v) => {
        handleClose();
        dispatch(setLocation(v));
        history.push("/");
    };

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    if(!location) return <div/>

    return <Grid sx={{paddingRight: "1rem"}}>
        <Button
            aria-label="venues"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            className={classes.locationsButton}
            variant="outlined"
        >
            {`${location.id} - ${location.name}`}
        </Button>
        {
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                onClose={handleClose}
            >
                <MenuItem onClick={() => selectLocation(null)}>{t('locationList')}</MenuItem>
            </Menu>
        }

    </Grid>
}