
const roles = {
    USER: "USER",
    ADMIN: "ADMIN",
    SUPER: "SUPERUSER"
};

let canEditResources = user => user.role !== roles.USER

export {
    roles,
    canEditResources
}