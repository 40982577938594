import React, {useEffect} from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import {makeStyles} from "@mui/styles";

import {get} from "../services/Client";

const useStyles = makeStyles({
    report: {
        height:"80vh"
    }
});

export default function PowerBI() {

    const [reportId, setReportId] = React.useState();
    const [embedUrl, setEmbedUrl] = React.useState();
    const [accessToken, setAccessToken] = React.useState();
    const classes = useStyles();

    useEffect(() => {
        get("getembedinfo").then(r => {
            setReportId(r.embedReports[0].reportId)
            setEmbedUrl(r.embedReports[0].embedUrl)
            setAccessToken(r.embedToken)
        })
    }, [])

    return (

        <PowerBIEmbed
            embedConfig = {{
                type: 'report',
                id: reportId,
                embedUrl: embedUrl,
                accessToken: accessToken,
                tokenType: models.TokenType.Embed,
                settings: {
                    panes: {
                        filters: {
                            expanded: false,
                            visible: false
                        }
                    },
                    background: models.BackgroundType.Transparent,
                }
            }}

            eventHandlers = {
                new Map([
                    ['loaded', function () {console.log('Report loaded');}],
                    ['rendered', function () {console.log('Report rendered');}],
                    ['error', function (event) {console.log(event.detail);}]
                ])
            }

            cssClassName = { classes.report }

            getEmbeddedComponent = {console.log}
        />
    )
}
