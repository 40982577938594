export const SET_LOCATION = "SET_LOCATION";

export function setLocation(location) {
	if (!location) {
		return {
			type: SET_LOCATION
		};
	} else return dispatch => {
		dispatch({
			type: SET_LOCATION,
			data: location
		});
	};
}
