import {useTranslation} from "react-i18next";
import {CircularProgress,IconButton,ImageList,ImageListItem,ImageListItemBar,ListSubheader} from "@mui/material";
import {useGetLayoutImage} from "../../services/ContentManager";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import React from "react";

export default function LayoutSelector({layoutsList, selected, onClick, title}) {

    const {t} = useTranslation();

    return <ImageList sx={{ width: "32.7rem", height: "30rem" }}>
        <ImageListItem key="Subheader" cols={2}>
            <ListSubheader component="div">{title || t('layouts')}</ListSubheader>
        </ImageListItem>
        {layoutsList.map((item) => <ImageItem layout={item} selected={item.id === selected} onClick={onClick}/>)}
    </ImageList>
}

function ImageItem({layout, selected, onClick}) {

    const {image} = useGetLayoutImage(layout.id);

    return <ImageListItem key={layout.name}>
        {image instanceof Blob ?
            <img
                src={URL.createObjectURL(image)}
                alt={layout.name}
                loading="lazy"
            /> :
            <CircularProgress color="primary" size="1.5rem"/>
        }
        <ImageListItemBar
            title={layout.name}
            actionIcon={
                <IconButton onClick={() => onClick(layout.id)}>
                    {selected ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                </IconButton>
            }
        />
    </ImageListItem>
}