import {useTranslation} from "react-i18next";
import {CircularProgress,Grid} from "@mui/material";
import {ConfirmIconButton,HoverableIconButton,NTMXGrid} from "react-layout-components/dist";
import {useGetLayoutImage,useGetMaps} from "../../services/ContentManager";
import React,{useContext,useState} from "react";
import MapModal from "../../components/modals/MapModal";
import {deleteElem,getErrorMessage,MAPS} from "../../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import AddNewButton from "../../components/react-layout-components-overwrite/AddNewButton";
import {canEditResources} from "../../services/PermissionManager";
import {UserContext} from "../App";

export default function Maps(){

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    let user = useContext(UserContext).data;
    let queryClient = useQueryClient();
    let {maps = []} = useGetMaps();
    let [modal, setModal] = useState(null);
    let location = useSelector(store => store.session.location);

    const deleteMap = (mapId) => {
        enqueueSnackbar(t('deleting'), {variant: "info"});
        deleteElem(`${MAPS}/${mapId}`)
            .then(() => enqueueSnackbar(t('deleted'),{variant : "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([MAPS, {lid: location.id}]));
    }

    const columns = [
        {headerName: t('name'), field: 'name', flex: 1},
        {
            headerName: t('layout'),
            field: 'layout',
            flex: 1,
            renderCell: ({value}) => <LayoutImage layout={value}/>
        },
        {headerName: t('sublocation'), field: 'subId', flex: 1},
        {headerName: t('locationID'), field: 'locationId', flex: 1},
    ]

    if(canEditResources(user)) columns.push({
        field: t('actions'),
        type: 'actions',
        width: 150,
        getActions: (params) => {
            return [
                <HoverableIconButton
                    src={require("../../images/icons/in-schermata-edit.svg").default}
                    hoverSrc={require("../../images/icons/in-schermata-edit-over.svg").default}
                    onClick={() => setModal(params.row)}
                />,
                <ConfirmIconButton
                    item={{
                        icon : require("../../images/icons/pop-up-beacon-delete-grigio.svg").default,
                        iconHover : require("../../images/icons/pop-up-beacon-delete-over.svg").default
                    }}
                    title={t('confirm')}
                    text={t('deleteWarningWithFemaleField', {field: t('map')})}
                    onConfirm={() => deleteMap(params.row.id)}
                />
            ]
        }
    })

    return <>
        <NTMXGrid
            title={t('maps')}
            rows={maps}
            columns={columns}
            rightButton={canEditResources(user) && <Grid container justifyContent={"flex-end"}>
                <AddNewButton onClick={() => setModal({})}/>
            </Grid>}
        />
        {modal !== null &&
            <MapModal defaultData={modal} onClose={() => {setModal(null)}}/>
        }
    </>
}

function LayoutImage({layout}) {

    const {image} = useGetLayoutImage(layout);
    if(!(image instanceof Blob)) return <CircularProgress color="primary" size="1.5rem"/>

    return <Grid>
        <img
            style={{ height: "3rem" }}
            src={URL.createObjectURL(image)}
            alt={layout}
        />
    </Grid>
}