import React from "react";
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    batteryContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        paddingTop: "0.3rem"
    },
    batteryOuter: {
        borderRadius: "3px",
        border: `${theme.palette.secondary.main} 1px solid`,
        padding: "1px",
        width: "3rem",
        height: "0.91rem"
    },
    batteryLevel: {
        borderRadius: "2px",
        height: "0.66rem"
    },
    batteryBump: {
        borderRadius: "2px",
        backgroundColor: `${theme.palette.secondary.main}`,
        margin: "2px",
        width: "1px",
        height: "3px"
    }
}));

export default function Battery ({batteryLevel}) {
    const classes = useStyles();

    return (
        <div className={classes.batteryContainer}>
            <div className={classes.batteryOuter}>
                <div className={classes.batteryLevel}
                     style={{
                         width: `${(batteryLevel !== null && batteryLevel !== undefined) ? batteryLevel : 0}%`,
                         backgroundColor: `${batteryLevel < 21 ? '#e50b0b' : "#73ad21"}`
                     }}
                />
            </div>
            <div className={classes.batteryBump}/>
        </div>
    );
}