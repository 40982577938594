import {MenuItem, Select} from "@mui/material";
import React from "react";

export default function LanguageSelect({value, onChange, languages, color='white'}) {

    return (
        <Select
            sx={{m : 1,minWidth : 120,display : {sm : 'inherit',xs : 'none', color: color}}}
            value={(value || '').substring(0,2)}
            onChange={onChange}
        >
            {
                languages.map(l => <MenuItem value={l.i18nCode}>{l.label}</MenuItem>)
            }
        </Select>
    );
}