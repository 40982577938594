import {useGetAttributes,useGetLayoutImage,useGetLayouts} from "../services/ContentManager";
import React,{useContext,useEffect,useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Card,CardActionArea,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    styled,
    Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {ConfirmIconButton,HoverableIconButton} from "react-layout-components";
import {useTranslation} from "react-i18next";
import {deleteElem,getErrorMessage,LAYOUTS} from "../services/Client";
import {useSnackbar} from "notistack";
import {useQueryClient} from "react-query";
import edit from "../images/icons/in-schermata-edit.svg";
import editHover from "../images/icons/in-schermata-edit-over.svg";
import LayoutModal from "../components/modals/LayoutModal";
import {UserContext} from "./App";
import {canEditResources} from "../services/PermissionManager";

const PillsGridContainer = styled(Grid)(({ theme }) => ({
    padding : "2rem",
    backgroundColor : theme.palette.primary.light,
    width : "auto"
}));

export default function Layouts() {

    let {layouts = []} = useGetLayouts()
    let {attributes = []} = useGetAttributes()
    let [groupedLayouts, setGroupedLayouts] = useState(null);

    useEffect(() => {
        let newGroupedLayouts = []
        attributes.map(att => {
            newGroupedLayouts.push({
                id: att.id,
                name: att.name,
                icon: att.negative,
                layouts: layouts.filter(l => l.attributeId === att.id)
            })
        })
        setGroupedLayouts(newGroupedLayouts)
    }, [layouts])

    return <Grid container justifyContent="space-between">
        <PillsGridContainer container xs={12}>
            {groupedLayouts?.map(att =>
                <AttributePill attribute={att}/>
            )}
        </PillsGridContainer>
    </Grid>
}

const CustomAccordion = styled(Accordion)(({ theme }) => ({
    margin: theme.spacing(1),
    '&.Mui-expanded': {
        border: "3px solid" + theme.palette.secondary.main,
    },
}));

function AttributePill({attribute}) {

    let user = useContext(UserContext).data;
    let [expanded,setExpanded] = useState(false);

    return (
        <Grid item lg={6} xs={12} key={attribute.id}>
            <CustomAccordion
                TransitionProps={{unmountOnExit : true}}
                square
                onChange={(e,expanded) => setExpanded(expanded)}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon color={expanded ? "secondary" : "primary"}/>}
                    aria-controls="panel1a-content"
                    id={attribute.id}
                >
                    <Grid alignItems={"center"} container direction="row">
                        <Grid item xs={1}>
                            <img alt={"icon"} src={attribute.icon} style={{ height: "2rem" }}/>
                        </Grid>
                        <Grid item xs={10}>
                            <Typography sx={{width:'100%', fontWeight: "bold"}} color={"textPrimary"}>
                                {attribute.name}
                            </Typography>
                        </Grid>
                    </Grid>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        {
                            attribute.layouts?.map(layout => {
                                return <Grid item xs={6} lg={4}>
                                    <LayoutCard layout={layout} editable={canEditResources(user)}/>
                                </Grid>
                            })
                        }
                        {canEditResources(user) &&
                            <Grid item xs={6} lg={4}>
                                <NewLayoutCard attributeId={attribute.id}/>
                            </Grid>
                        }
                    </Grid>
                </AccordionDetails>
            </CustomAccordion>
        </Grid>
    );
}

const CustomCard = styled(Card)(({ theme }) => ({
    border: "2px solid",
    borderColor: theme.palette.primary.main
}));

const CustomCardContent = styled(CardContent)(({ theme }) => ({
    borderTop: "1px solid",
    borderColor: theme.palette.primary.main
}));

function LayoutCard({layout, editable}) {

    const {t} = useTranslation();
    const {enqueueSnackbar} = useSnackbar();
    let queryClient = useQueryClient();
    const {image} = useGetLayoutImage(layout.id);
    let [modal, setModal] = useState(null)

    const deleteLayout = () => {
        enqueueSnackbar(t('deleting'), {variant: "info"});
        deleteElem(`${LAYOUTS}/${layout.id}`)
            .then(() => enqueueSnackbar(t('deleted'),{variant : "success"}))
            .catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}))
            .finally(() => queryClient.invalidateQueries([LAYOUTS]));
    }


    return <CustomCard sx={{ maxWidth: 345 }}>
        <CardMedia
            component="img"
            alt={t('downloading')}
            height="140"
            image={image instanceof Blob && URL.createObjectURL(image)}
        />
        <CustomCardContent>
            <Typography variant="h5" component="div">
                {layout.name}
            </Typography>
        </CustomCardContent>
        <CardActions>
            <Grid container direction="row" justifyContent="flex-end">
                {editable && <>
                    <HoverableIconButton src={edit} hoverSrc={editHover} onClick={() => setModal(layout)}/>
                    <ConfirmIconButton
                        item={{
                            icon : require("../images/icons/pop-up-beacon-delete-grigio.svg").default,
                            iconHover : require("../images/icons/pop-up-beacon-delete-over.svg").default
                        }}
                        title={t('confirm')}
                        text={t('deleteWarningWithField',{field : t('layout')})}
                        onConfirm={deleteLayout}
                    />
                </>}
            </Grid>
        </CardActions>
        {modal !== null && <LayoutModal defaultData={modal} onClose={() => setModal(null)}/>}
    </CustomCard>
}

const CustomAddNewCard = styled(Card)(({ theme }) => ({
    border: "2px solid",
    borderColor: theme.palette.secondary.main
}));

function NewLayoutCard({attributeId}) {

    const {t} = useTranslation();
    let [modal, setModal] = useState(null)

    return <CustomAddNewCard sx={{ maxWidth: 345, minHeight: 272 }}>
        <CardActionArea sx={{ maxWidth: 345, minHeight: 272 }} component={Button} onClick={() => setModal({attributeId})}>
            <Grid container justifyContent="center" alignItems="center">
                <Grid item>
                    <Typography variant="h6" component="div">
                        {t('addLayout')}
                    </Typography>
                </Grid>
            </Grid>
        </CardActionArea>
        {modal !== null && <LayoutModal defaultData={modal} onClose={() => setModal(null)}/>}
    </CustomAddNewCard>
}