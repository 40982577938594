//Lista delle voci del menu
//Aggiungi un elemento nell'array per avere una nuova voce

import {Trans} from "react-i18next"

const dashboard = {
	name: <Trans i18nKey="dashboard"/>,
	url: "/dashboard",
	icon: require("../images/icons/menu-home.svg").default,
	iconHover: require("../images/icons/menu-home-over.svg").default,
	iconSelected: require("../images/icons/menu-home-select.svg").default
};

const maps = {
	name: <Trans i18nKey="maps"/>,
	url: "/maps",
	icon: require("../images/icons/menu-maps.svg").default,
	iconHover: require("../images/icons/menu-maps-over.svg").default,
	iconSelected: require("../images/icons/menu-maps-select.svg").default
};

const locationsMap = {
	name: <Trans i18nKey="locationsOnMap"/>,
	url: "/locationsMap",
	icon: require("../images/icons/menu-maps.svg").default,
	iconHover: require("../images/icons/menu-maps-over.svg").default,
	iconSelected: require("../images/icons/menu-maps-select.svg").default
};

const tours = {
	name: <Trans i18nKey="thematicTours"/>,
	url: "/tours",
	icon: require("../images/icons/menu-routes.svg").default,
	iconHover: require("../images/icons/menu-routes-over.svg").default,
	iconSelected: require("../images/icons/menu-routes-select.svg").default
};

const content = {
	name: <Trans i18nKey="content"/>,
	url: "/content",
	icon: require("../images/icons/menu-content.svg").default,
	iconHover: require("../images/icons/menu-content-over.svg").default,
	iconSelected: require("../images/icons/menu-content-select.svg").default
};

const users = {
	name: <Trans i18nKey="userArchive"/>,
	url: "/users",
	icon: require("../images/icons/menu-user-management.svg").default,
	iconHover: require("../images/icons/menu-user-management-over.svg").default,
	iconSelected: require("../images/icons/menu-user-management-select.svg").default,
};

const monitoring = {
	name: <Trans i18nKey="monitoring"/>,
	url: "/monitoring",
	icon: require("../images/icons/menu-monitoring.svg").default,
	iconHover: require("../images/icons/menu-monitoring-over.svg").default,
	iconSelected: require("../images/icons/menu-monitoring-select.svg").default,
};

const layouts = {
	name: <Trans i18nKey="layouts"/>,
	url: "/layouts",
	icon: require("../images/icons/menu-layout.svg").default,
	iconHover: require("../images/icons/menu-layout-over.svg").default,
	iconSelected: require("../images/icons/menu-layout-select.svg").default
};

const admin = {
	name: <Trans i18nKey="accessOverview"/>,
	url: "/administration",
	icon: require("../images/icons/menu-advanced.svg").default,
	iconHover: require("../images/icons/menu-advanced-over.svg").default,
	iconSelected: require("../images/icons/menu-advanced-select.svg").default
};

const stats = {
	name: <Trans i18nKey="bi"/>,
	url: "/analytics",
	icon: require("../images/icons/menu-analytics.svg").default,
	iconHover: require("../images/icons/menu-analytics-over.svg").default,
	iconSelected: require("../images/icons/menu-analytics-select.svg").default
};


export default {
	admin,
	content,
	dashboard,
	maps,
	monitoring,
	tours,
	users,
	locationsMap,
	stats,
	layouts
};

