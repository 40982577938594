import {useState} from "react";
import {useTranslation} from "react-i18next";
import {Redirect} from "react-router-dom";
import {useSelector} from "react-redux";

import {FilterAndSearchBar} from "react-layout-components/dist";
import Beacons from "./Content/Beacons";
import Maps from "./Content/Maps";

export default function Content() {

    const {t} = useTranslation();
    let [selected, setSelected] = useState(0);
    let location = useSelector(store => store.session.location);

    let filters = [
        {
            id: 0,
            name: t('maps'),
            component: <Maps/>
        },
        {
            id: 1,
            name: t('beacons'),
            component: <Beacons/>
        }
    ]

    if (!location) return <Redirect to={"/"}/>;

    return (
        <div>
            <FilterAndSearchBar
                filters={filters}
                onFilter={setSelected}
                selected={selected}
            />
            {filters.find(f => f.id === selected)?.component}
        </div>
    );
}