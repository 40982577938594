import React, {useEffect, useState} from 'react';
import {Paper} from "@mui/material";
import {makeStyles} from "@mui/styles";
import GoogleMap from "../components/mapview/GoogleMap";
import {get,LOCATIONS} from "../services/Client";
import {useTranslation} from "react-i18next";

const useStyles = makeStyles(theme => ({
	page: {
		padding: theme.spacing(2)
	}
}));

export default function LocationsMap() {

	const {t} = useTranslation();
	let classes = useStyles();
	let [locations, setLocations] = useState();

	//todo sposta la query nel contentManager e salva nello stato center e markers con uno useEffect su [locations] che li popola
	useEffect(() => {
		get(`${LOCATIONS}/positions`).then(setLocations);
	}, []);

	const devicePopUp = (id, name) =>
		<div style={{
			backgroundColor: "white",
			display: "flex",
			flexDirection: "column",
			padding: "0.5rem",
			width: "10rem",
			fontSize: "0.85rem"
		}}>
			<div style={{flexGrow: 2, paddingBottom: "0.25rem"}}>
				<div style={{fontWeight: "bold"}}>{t('id')}</div>
				<div>{id}</div>
			</div>
			<div style={{flexGrow: 10}}>
				<div style={{fontWeight: "bold"}}>{t('name')}</div>
				<div>{name}</div>
			</div>
		</div>;

	let center, markers = [];
	locations && locations.forEach(l => l.positions && l.positions.forEach(p => markers.push({
		position: {
			lat: p.lat,
			lng: p.lng
		},
		title: l.name,
		data: devicePopUp(l.locationId, l.name)
	})));

	center = {
		lat: parseFloat(process.env.REACT_APP_MAP_CENTER_X),
		lng: parseFloat(process.env.REACT_APP_MAP_CENTER_Y)
	};

	return (
		<Paper className={classes.page}>
			<GoogleMap
				center={center}
				markers={markers}
				style={{
					width: "100%",
					height: "75vmin",
					background: "grey"
				}}
			/>
		</Paper>
	);
}
