import React,{useContext,useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import GoogleMap from "../components/mapview/GoogleMap";
import {useGetAttributes,useGetMaps} from "../services/ContentManager";
import edit from "../images/icons/in-schermata-edit.svg";
import save from "../images/icons/in-schermata-salva.svg";
import discard from "../images/icons/in-schermata-beacon-chiudi.svg";
import editRed from "../images/icons/in-schermata-edit-over.svg";
import editDisabled from "../images/icons/pop-up-beacon-edit.svg";
import {setLocation} from "../redux/session/actions";
import {getErrorMessage, put, LOCATIONS} from "../services/Client";
import {useSnackbar} from "notistack";
import {makeStyles} from "@mui/styles";
import {Avatar,Badge,Grid,IconButton,Paper,styled} from "@mui/material";
import {TextInput, TextItem} from "react-layout-components/dist";
import {useTranslation} from "react-i18next";
import EditLocationAttributeModal from "../components/modals/EditLocationAttributeModal";
import {UserContext} from "./App";
import {canEditResources} from "../services/PermissionManager";

const useStyles = makeStyles(theme => ({
	page: {
		padding: theme.spacing(2)
	},
	container: {
		marginBottom: theme.spacing(2)
	},
	box: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		borderBottom: `${theme.palette.secondary.main} 1px solid`,
		color: theme.palette.primary.dark,
		fontWeight: "bold",
		flexGrow: 1,
	},
	textField: {
		padding: theme.spacing(1),
		margin: theme.spacing(1),
		border: `${theme.palette.secondary.main} 1px solid`,
		color: theme.palette.primary.dark,
		fontWeight: "bold",
		flexGrow: 1
	}
}));

const SmallAvatar = styled(Avatar)(({ theme }) => ({
	width: 30,
	height: 30,
	border: `2px solid ${theme.palette.background.main}`,
}));

export default function Dashboard() {

	const {t} = useTranslation();
	let user = useContext(UserContext).data;
	let {attributes = []} = useGetAttributes()
	let {maps = []} = useGetMaps();
	let location = useSelector(store => store.session.location);
	let [editLocation, setEditLocation] = useState(location);
	let [isEditing, setIsEditing] = useState(false);
	let [isEditingAttribute, setIsEditingAttribute] = useState(false);
	const dispatch = useDispatch();
	let classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();

	const form = [
		{
			name: "name",
			label: t('name')
		},
		{
			name: "description",
			label: t('description')
		},
		{
			name: "locationId",
			label: t('id'),
			notEditable: true
		},
		{
			name: "n",
			label: t('nBeacon'),
			notEditable: true
		},
		{
			name: "address",
			label: t('address')
		},
		{
			name: "city",
			label: t('city')
		},
	]

	let attribute = location.attribute ? attributes.find(a => a.id === location.attribute) : attributes[1];
	let center = {
		lat: parseFloat(process.env.REACT_APP_MAP_CENTER_X),
		lng: parseFloat(process.env.REACT_APP_MAP_CENTER_Y)
	}, markers = [];
	if (location.positions) {
		location.positions.forEach(p => markers.push({
			position: {
				lat: p.lat,
				lng: p.lng
			},
			title: location.name
		}));
		center = {lat: location.positions[0].lat, lng: location.positions[0].lng};
	}

	const onChange = (name, value) => {
		let newLocation = Object.assign({}, editLocation, {[name]: value});
		setEditLocation(newLocation);
	};

	const saveEditLocation = () => {
		enqueueSnackbar(t('saving'), {variant: "info"});
		put(LOCATIONS, {body: editLocation, elem: location.id}).then(() => {
			enqueueSnackbar(t('saved'), {variant: "success"});
			dispatch(setLocation(editLocation));
			setIsEditing(false);
		}).catch(e => enqueueSnackbar(getErrorMessage(e), {variant: "error"}));
	};

	const discardEditLocation = () => {
		setEditLocation(location);
		setIsEditing(false);
	};

	const getIsEditingForm = () => {
		return <Grid container spacing={3} style={{padding: "0.5rem 1rem"}}>
			{
				form.map(f => !f.notEditable && <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
					<TextInput value={editLocation[f.name]} onTextChange={(value) => onChange(f.name, value)}
							   label={f.label} color="dark"/>
				</Grid>)
			}
		</Grid>
	}

	return (
		<Paper className={classes.page}>
			<Grid container justifyContent="space-between" className={classes.container}>
				<Grid item/>
				{canEditResources(user) &&
					<Grid item alignItems={"flex-end"}>
						{isEditing?
							<Grid>
								<IconButton onClick={saveEditLocation}>
									<img src={save} alt="save"/>
								</IconButton>
								<IconButton onClick={discardEditLocation}>
									<img src={discard} alt="discard"/>
								</IconButton>
							</Grid>
							:
							<IconButton onClick={() => setIsEditing(true)}>
								<img src={edit} alt="edit"/>
							</IconButton>
						}
					</Grid>
				}
				<Grid item xs={12} style={{display: "flex", "justify-content": "center"}}>
					{attribute &&
						<Badge
							overlap="circular"
							anchorOrigin={{vertical : 'top',horizontal : 'right'}}
							badgeContent={process.env.REACT_APP_ENVIRONMENT === "demo-monaco" &&
								<SmallAvatar alt="edit"
											 src={maps.length === 1 ? editRed : editDisabled}
											 onClick={() => {
												 if(maps.length === 1) setIsEditingAttribute(true)
											 }}
								/>
							}
						>
							<Avatar alt="attribute" style={{height : "5rem",width : "5rem"}} src={attribute.negative}/>
						</Badge>
					}
				</Grid>
			</Grid>

			<Grid container alignItems={"flex-end"} className={classes.container}>
				{
					isEditing ?
						getIsEditingForm()
						:
						form.map(f => <Grid item xs={12} sm={6} alignItems={"flex-end"} style={{display: "flex"}}>
							<TextItem label={f.label}>
								{location[f.name] || ""}
							</TextItem>
						</Grid>)
				}
			</Grid>
			{
				attribute && attribute.location &&
				<GoogleMap center={center} markers={markers} zoom={15}/>
			}
			{!!isEditingAttribute &&
				<EditLocationAttributeModal
					open={isEditingAttribute}
					onClose={() => setIsEditingAttribute(false)}
				/>
			}
		</Paper>
	);
}
