//React
import React,{Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
//Redux
import {Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import {persistStore, persistReducer, createTransform} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import {PersistGate} from 'redux-persist/integration/react';
import {createLogger} from "redux-logger";
import thunk from "redux-thunk";
import reducers from "./redux";

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import App from './containers/App';
import * as serviceWorker from './serviceWorker';
import {LicenseInfo} from "@mui/x-data-grid-pro";
import {LoadScript} from "@react-google-maps/api";
import i18n from "i18next";
import './i18n';

import {
    QueryClient,
    QueryClientProvider
} from "react-query";
import {Helmet} from "react-helmet";

let middleware = [thunk];

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    // Dev-only middleware
    console.log("DEV_only middleware");
    middleware = [
        ...middleware,
        createLogger() // Logs state changes to the dev console
    ];
}

const persistConfig = {
    key: 'root',
    storage,
    transforms: [
        createTransform(inboundState => inboundState, outboundState => {
            return {
                ...outboundState,
                ready:false
            }
        }, {whitelist:['user']})
    ]
};
const persistedReducer = persistReducer(persistConfig, reducers);

const createStoreWithMiddleware = compose(applyMiddleware(...middleware))(createStore);
let store = createStoreWithMiddleware(persistedReducer);
let persistor = persistStore(store);

Bugsnag.start({
    apiKey: 'b54a91baf5ce278e6c7576a439daec5a',
    plugins: [new BugsnagPluginReact()],
    enabledReleaseStages: ['production']
});

LicenseInfo.setLicenseKey(
    '96982ca227141c27dcacdc03f96229ecT1JERVI6NDA4ODQsRVhQSVJZPTE2ODAzNjY1NjMwMDAsS0VZVkVSU0lPTj0x',
);

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React);

const queryClient = new QueryClient();

ReactDOM.render(
    <React.StrictMode>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{process.env.REACT_APP_TITLE}</title>
        </Helmet>
        <ErrorBoundary>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <QueryClientProvider client={queryClient}>
                        <LoadScript googleMapsApiKey={"AIzaSyASkt6JkNa5yn_2cXq-tQGbBmGp8K-3QII"} language={i18n.language}>
                            <Suspense fallback="Loading...">
                                <Router>
                                    <App/>
                                </Router>
                            </Suspense>
                        </LoadScript>
                    </QueryClientProvider>
                </PersistGate>
            </Provider>
        </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();