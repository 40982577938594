import React, {useState} from 'react';
import {GoogleMap, InfoWindow, Marker, MarkerClusterer} from '@react-google-maps/api';

const options = {
	disableDefaultUI: true,
	zoomControl: true,
	mapTypeControl: true
};

const mapContainerStyle = {
	height: "80vh",
	width: "100%"
};

export default function MapContainer({markers, center, zoom = 10}) {

	let [infowindow, setInfowindow] = useState();

	return <GoogleMap
		id="map"
		mapContainerStyle={mapContainerStyle}
		center={center}
		options={options}
		zoom={zoom}
	>
			<MarkerClusterer>
				{(clusterer) =>
					markers.map((e, i) => (
						<Marker
							key={i}
							position={e.position}
							title={e.title}
							onClick={() => setInfowindow(e)}
							clusterer={clusterer}
						/>
					))
				}
			</MarkerClusterer>
			{infowindow && infowindow.data &&
			<InfoWindow
				position={infowindow.position}
				onCloseClick={() => setInfowindow(null)}
			>
				{infowindow.data}
			</InfoWindow>
			}
	</GoogleMap>
}
