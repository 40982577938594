import React,{useContext,useState} from "react";
import {useTranslation} from "react-i18next";
import clsx from "clsx";

import {Grid,Paper} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {NTMXGrid} from "react-layout-components/dist";
import AddNewButton from "../components/react-layout-components-overwrite/AddNewButton";

import {useGetUsers} from "../services/ContentManager";
import UserModal from "../components/modals/UserModal";
import {canEditResources,roles} from "../services/PermissionManager";
import {UserContext} from "./App";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        color: theme.palette.primary.dark,
        fontWeight: "700",
        padding:"1rem",
        "& div": {
            paddingLeft:"1rem",
            paddingRight:"1rem",
            "&:hover":{
                color: theme.palette.secondary.main,
                cursor:"pointer"
            }
        },
    },
    selected: {
        color: theme.palette.secondary.main,
    },
}));

export default function UserManagement(){

    const {t} = useTranslation();
    const classes = useStyles();
    let {users} = useGetUsers();
    let [selected, setSelected] = useState(null);
    let [modal, setModal] = useState(false);
    let user = useContext(UserContext).data;

    let data;
    if (selected) data = users.filter(u => u.role === selected);
    else data = users.filter(u => u.role !== roles.SUPER);

    return <Paper>
        <NTMXGrid
            columns={[
                {headerName: t('firstName'), field: 'firstName', flex: 1},
                {headerName: t('lastName'), field: 'lastName', flex: 1},
                {headerName: t('email'), field: 'email', flex: 1},
                {headerName: t('role'), field: 'role', flex: 1,
                    renderCell: ({value}) => value === roles.ADMIN ? t('admin') : t('maintainer')}
            ]}
            rows={data}
            centerButton={<div className={classes.root}>|
                <div onClick={() => setSelected(null)} className={clsx({[classes.selected]:selected===null})}>{t('all').toUpperCase()}</div>|
                <div onClick={() => setSelected(roles.USER)} className={clsx({[classes.selected]:selected===roles.USER})}>{t('maintainer').toUpperCase()}</div>|
                <div onClick={() => setSelected(roles.ADMIN)} className={clsx({[classes.selected]:selected===roles.ADMIN})}>{t('admin').toUpperCase()}</div>|
            </div>}
            rightButton={canEditResources(user) && <Grid container justifyContent={"flex-end"}>
                <AddNewButton onClick={() => setModal(true)}/>
            </Grid>}
        />

        <UserModal open={modal} onClose={() => setModal(false)}/>
    </Paper>
}