import {useQuery, useQueryClient} from "react-query";
import {useSelector} from "react-redux";
import {
    ALL_REVISION,
    BEACONS,
    get,
    LOCATIONS,
    MAPS,
    REVISION_ENTITY,
    USERS,
    LAYOUTS,
    IMAGE,
    download,
    ATTRIBUTES
} from "./Client";
import {db} from "../db";
import {useLiveQuery} from "dexie-react-hooks";
import attributes from "../constants/attributes"

export function useGetAttributes() {
    const {status, data = [], error} = useQuery([ATTRIBUTES], () => get(ATTRIBUTES));
    return {
        status,
        attributes: data.length !== 0 ? attributes.filter(a => data.some(at => at.id === a.id)) : [],
        error
    };
}

export function useGetUsers(){
    let location = useSelector(store => store.session.location);
    let params = {};
    if (location) params.location = location.id;
    const {status, data, error} = useQuery([USERS, params], () =>
        //todo il back non prenvede questo filtro su location, quindi a prescindere torna tutti gli utenti
        get(USERS, {params})
    );
    return {status, users: data || [], error};
}

export function useGetEntity(type, id ,revision) {
    const {status, data, error} = useQuery([REVISION_ENTITY[type], id, revision], () =>
        id && get(`${REVISION_ENTITY[type]}/${id}`,{params: {rev: revision}})
    );
    return {status, entity: data || [], error};
}

export function useGetMaps() {
    let location = useSelector(store => store.session.location);
    const {status, data, error} = useQuery([MAPS, {lid: location.id}], () =>
        get(MAPS, {params: {lid: location.id}})
    );
    return {status, maps: data || [], error};
}

export function useGetBeacons() {
    let location = useSelector(store => store.session.location);
    const {status, data, error} = useQuery([BEACONS, {location: location.id}], () =>
        get(BEACONS, {params: {location: location.id}})
    );
    return {status, beacons: data || [], error};
}

export function useGetLayouts() {
    const {status, data, error} = useQuery([LAYOUTS], () =>
        get(LAYOUTS)
    );
    return {status, layouts: data || [], error};
}

export function useGetLayoutImage(layoutId) {
    const {status, data, error} = useQuery([LAYOUTS, layoutId, IMAGE], () =>
        download(`${LAYOUTS}/${layoutId}/${IMAGE}`), {staleTime: Infinity}
    );
    return {status, image: data?.data || null, error};
}

export function synchronizeRevisions() {
    db.fetchStatus.get("allRevisions").then(status => {
        if(status?.status === "ok") synchronizeRevisionsWithTimestamp(status.lastUpdate);
        else synchronizeRevisionsWithoutTimestamp()
    })
}

export function synchronizeLocations() {
    db.fetchStatus.put({key: "locations", status: "loading"})
    get(LOCATIONS)
        .then(locations => {
            db.locations.clear()
            db.locations.bulkPut(locations)
            db.fetchStatus.put({key: "locations", status: "ok"})
        })
        .catch(e => db.fetchStatus.put({key: "locations", status: "error", error: e}))
}

function synchronizeRevisionsWithoutTimestamp() {

    db.fetchStatus.put({key: "allRevisions", status: "loading"})
    let timestamp = (new Date()).getTime()

    get(ALL_REVISION).then(allRevisions => {
        db.allRevisions.clear()
        db.allRevisions.bulkPut(allRevisions)
            .then(() => db.fetchStatus.put({key: "allRevisions", status: "ok", lastUpdate: timestamp}))
            .catch(e => db.fetchStatus.put({key: "allRevisions", status: e}))
    })
}

function synchronizeRevisionsWithTimestamp(lastTimestamp){

    db.fetchStatus.put({key: "allRevisions", status: "updating"})
    let timestamp = (new Date()).getTime()

    get(ALL_REVISION, {params: {minTimestamp: lastTimestamp}}).then(newRevisions => {
        db.allRevisions.bulkPut(newRevisions)
            .then(() => db.fetchStatus.put({key: "allRevisions", status: "ok", lastUpdate: timestamp}))
            .catch(e => db.fetchStatus.put({key: "allRevisions", status: e}))
    })
}

export function useGetRevisions() {

    const revisions = useLiveQuery(() => db.allRevisions.toArray()) || []
    const status = useLiveQuery(() => db.fetchStatus.get("allRevisions")) || {status: "loading"}

    return {status, revisions}
}

export function useGetLocations() {

    const locations = useLiveQuery(() => db.locations.toArray()) || []
    const status = useLiveQuery(() => db.fetchStatus.get("locations")) || {status: "loading"}

    return {status, locations}
}