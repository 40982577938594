import React,{useContext,useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {useSelector} from "react-redux";
import {UserContext} from "./App";

import {synchronizeLocations,synchronizeRevisions} from "../services/ContentManager";

import {Page404} from "react-layout-components/dist";
import Dashboard from "./Dashboard";
import LocationList from "./LocationList";
import ProfilePage from "./ProfilePage";
import LocationsMap from "./LocationsMap";
import UserManagement from "./UserManagement";
import Administration from "./Administration";
import PowerBI from "./PowerBI";
import Maps from "./Maps";
import Content from "./Content";
import Layouts from "./Layouts";

export default function Main() {

    let location = useSelector(store => store.session.location);
    const user = useContext(UserContext).data;

    useEffect(() => {
        synchronizeRevisions();
        synchronizeLocations();
    },[])

    return <Switch>

        <Route path="/dashboard">
            {location ?
                <Dashboard/> :
                <LocationList/>
            }
        </Route>

        <Route path={"/profile/:uid"}>
            <ProfilePage/>
        </Route>
        <Redirect from="/profile" to={"/profile/" + user.id}/>

        <Route path={"/locationsMap"}>
            <LocationsMap/>
        </Route>
        <Route path={"/users"}>
            <UserManagement/>
        </Route>
        <Route path={"/administration"}>
            <Administration/>
        </Route>
        {process.env.REACT_APP_ENVIRONMENT !== "demo-monaco" && process.env.REACT_APP_ENVIRONMENT !== "production" &&
            <Route path={"/analytics"}>
                <PowerBI/>
            </Route>
        }
        <Route path={["/maps/:map/beacon/:id", "/maps"]}>
            <Maps/>
        </Route>
        <Route path={"/content"}>
            <Content/>
        </Route>
        {process.env.REACT_APP_ENVIRONMENT === "demo-monaco" &&
            <Route path={"/layouts"}>
                <Layouts/>
            </Route>
        }

        <Redirect exact from="/" to="/dashboard"/>
        <Route path={"/"}>
            <Page404/>
        </Route>
    </Switch>
}
