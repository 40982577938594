import {useState} from "react";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

import {Table,TableBody,TableCell,TableRow} from "@mui/material";
import {makeStyles,withStyles} from "@mui/styles";
import {NTMXGrid} from "react-layout-components/dist";

import {useGetEntity,useGetLocations,useGetRevisions} from "../services/ContentManager";
import EntityModal from "../components/modals/EntityModal";


const useStyles = makeStyles({
    table: {
        minWidth: 70,
    }
});

const StyledTableCell = withStyles((theme) => ({
    root: {
        borderBottom: '#0000',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:hover': {
            background: '#0000',
        }
    },
}))(TableRow);

export default function Administration() {

    const {t} = useTranslation();
    let [open, setOpen] = useState(false);
    let [popupEntity, setPopupEntity] = useState(0);
    const {revisions, status} = useGetRevisions()
    revisions?.map((r,i) => r.internalId = i)

    function onClose() {
        setOpen(false);
    }

    const MAP_ENTITY_TYPE = [t('location'), t('beacon'), t('mapLayout')];
    const MAP_REVISION_TYPE = [t('createAction'), t('updateAction'), t('deleteAction')];

    const columns = [
        {
            headerName: t('timestamp'),
            field: 'timestamp',
            flex: 1,
            renderCell: params => dayjs(new Date(params.value)).format("DD/MM/YYYY HH:mm:ss")
        },
        {
            headerName: t('author'),
            field: 'author',
            flex: 1.5
        },
        {
            headerName: t('revisionType'),
            field: 'revisionType',
            flex: 1,
            renderCell: params => MAP_REVISION_TYPE[params.value]
        },
        {
            headerName: t('entityType'),
            field: 'entityType',
            flex: 1,
            renderCell: params => MAP_ENTITY_TYPE[params.value]
        },
        {
            headerName: t('entity'),
            field: 'entityId',
            flex: 1,
            renderCell: params => <EntityName row={params.row}/>
        },
        {
            headerName: t('updatedFields'),
            field: 'updatedFields',
            flex: 1,
            renderCell: params => params.value && <UpdatedList row={params.row}/>
        }
    ]

    return (
        <>
            <NTMXGrid
                columns={columns}
                rows={revisions}
                getRowId={(row) => revisions && row.internalId}
                onRowClick={(params) => {
                    setOpen(true);
                    setPopupEntity(params.row);
                }}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'timestamp', sort: 'desc' }],
                    },
                }}
                loading={revisions.length === 0 && status?.status === "loading"}
            />
            {<Modal row={popupEntity} open={open} onClose={onClose}/>}
        </>
    )
}

function EntityName({row}) {
    let {entity} = useGetEntity(row.entityType, row.entityId, row.entityRev);
    switch (row.entityType) {
        //location
        case 0:
            return <div>{entity.name || row.entityId}</div>;
        case 1:
            return <div>{entity.uuid || row.entityId}</div>;
        case 2: {
            return <div>{entity.name || row.entityId}</div>;
        }
        default: {
            return <div/>
        }
    }
}


function UpdatedList(row) {
    const classes = useStyles();

    let rowList = row.updatedFields?.map((item) => {
        return (
            <StyledTableRow key={[item.field, row.entityId]}>
                <StyledTableCell>
                    <b>{item.field}</b>
                </StyledTableCell>
                <StyledTableCell>
                    {item.value}
                </StyledTableCell>
            </StyledTableRow>
        );
    });
    return (
        <div>
            <Table className={classes.table} size="small">
                <TableBody>
                    {rowList}
                </TableBody>
            </Table>
        </div>
    );
}

function Modal({row, open, onClose}) {

    const {t} = useTranslation();
    let {entity} = useGetEntity(row.entityType, row.entityId, row.entityRev);
    let {locations} = useGetLocations();

    //const MAP_ENTITY_TYPE = ['Location', 'Beacon', 'Map Layout'];
    switch (row.entityType) {
        case 0:
            //location
            entity = {
                [t('locationId')]: entity.locationId,
                [t('name')]: entity.name,
                [t('description')]: entity.description,
                [t('address')]: entity.address,
                [t('city')]: entity.city,
            };
            break;
        case 1:
            //beacon
            entity = {
                [t('uuid')]: entity.uuid,
                [t('major')]: entity.major,
                [t('minor')]: entity.minor,
                'X': entity.x,
                'Y': entity.y
            };
            break;
        case 2: {
            let location = locations.find(l => l.id == entity.locationId);
            //map
            entity = {
                [t('mapName')]: entity.name,
                [t('location')]: location && location.name,
                [t('locationID')]: entity.locationId,
                [t('layout')]: entity.layout,
                [t('sublocation')]: entity.subId,
            };
            break;
        }
        default: {
            entity = {};
            break;
        }
    }

    return (
        <EntityModal entity={entity} open={open} onClose={onClose} updatedFields={row.updatedFields || []}/>
    );
}