import React, {useState} from "react";
import {TextInput} from "react-layout-components/dist";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import {ListItemIcon,ListItemText,MenuItem} from "@mui/material";
import {useGetAttributes} from "../../services/ContentManager";
import BaseModal from "../react-layout-components-overwrite/BaseModal";

export default function LocationModal({open, onClose, onSubmit}) {

    const {t} = useTranslation();
    let {attributes = []} = useGetAttributes();
    let [location, setLocation] = useState({});
    let [errors, setErrors] = useState({});

    function onChange(name, value) {
        let newLocation = Object.assign(location, {[name]: value});
        setLocation(newLocation);
        setErrors({});
    }

    function submit() {
        if (!location.name || location.name === "") {
            let newErrors = {name: t('mandatoryField')};
            setErrors(newErrors);
            return;
        }
        onSubmit(location);
    }

    return (
        <BaseModal open={open} onClose={onClose} onSave={submit}>
            <Grid container spacing={4} style={{padding: "0.5rem 1rem", marginBottom: "1.5rem"}}>

                <Grid item xs={12} style={{display: "flex"}}>
                    <TextInput label={t('locationType')} value={location.attribute || ''} type="select" select
                               onTextChange={value => onChange("attribute", value)} color="fullDark">
                        {attributes.map(a =>
                            <MenuItem key={a.id} value={a.id}>
                                <Grid container direction="row">
                                    <ListItemIcon>
                                        <img src={a.icon}/>
                                    </ListItemIcon>
                                    <ListItemText primary={a.name}/>
                                </Grid>
                            </MenuItem>)}
                    </TextInput>
                </Grid>

                <Grid item xs={12}>
                    <TextInput required label={t('name')} value={location.name} onTextChange={value => onChange("name", value)}
                               error={errors.name} color="fullDark"/>
                </Grid>
                <Grid item xs={12}>
                    <TextInput label={t('description')} value={location.description} color="fullDark"
                               onTextChange={value => onChange("description", value)}/>
                </Grid>
                {
                    location.attribute &&
                    attributes.length > 0 &&
                    attributes.find(a => a.id === location.attribute).location &&
                    <>
                        <Grid item xs={12}>
                            <TextInput label={t('address')} value={location.address} color="fullDark"
                                       onTextChange={value => onChange("address", value)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextInput label={t('city')} value={location.city} color="fullDark"
                                       onTextChange={value => onChange("city", value)}/>
                        </Grid>
                    </>
                }
            </Grid>
        </BaseModal>
    );
}
