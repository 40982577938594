import menuItems from "../constants/_nav";
import {useSelector} from "react-redux";

let useGetMenu = () => {

    //todo da filtrare con i permessi
    let location = useSelector(store => store.session.location);

    let items = [menuItems.dashboard];

    if (location) {
        items.push(menuItems.maps);
        items.push(menuItems.content);
    } else {
        items.push(menuItems.locationsMap);
    }
    items.push(menuItems.users);
    if(process.env.REACT_APP_ENVIRONMENT === "demo-monaco") items.push(menuItems.layouts)
    items.push(menuItems.admin);

    if(process.env.REACT_APP_ENVIRONMENT !== "demo-monaco" && process.env.REACT_APP_ENVIRONMENT !== "production")
        items.push(menuItems.stats);

    return items;
};

export {useGetMenu};